import React, { useState, useEffect } from 'react';
import './Slideshow.css';



const slides = [
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fteacher_full.jpg?alt=media&token=8a5b6ebf-2eed-452d-8b69-f869d5f89f54', title: 'A Teacher' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fwarrior.jpg?alt=media&token=aa148848-2037-4505-986e-55e4acd6215b', title: 'A Fighter' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_mother_full.JPG?alt=media&token=da4967b0-310b-4a49-94ff-f037adf632d8', title: 'A Mother' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_sister.jpg?alt=media&token=fb4fd4b4-ef2f-493b-9499-03043c504bc9', title: 'A Sister' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_daughter.jpg?alt=media&token=8ce12b55-57ab-4919-9207-8f89564365e7', title: 'A Daughter' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_water_soul.jpg?alt=media&token=816e3377-ad49-4c61-bc0e-a1f8cd1cd8a4', title: 'A Fish' },
    { path: 'https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fa_Student.jpg?alt=media&token=c4ed7b09-7b56-4614-a0af-2029b5bc7ee4', title: 'A Student' },
];

const Slideshow = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slideshow-container">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`slide ${index === currentImageIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${slide.path})` }}
                >
                    <div className="slide-title">{slide.title}</div>
                </div>
            ))}
        </div>
    );
};


export default Slideshow;
