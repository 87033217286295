import React from 'react';
import '../src/BackgroundFrame.css';

const BackgroundFrameRight = ({ backgroundImage, title, subtitle }) => {
    return (
        <div className="background-frame-right" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="text-container-right">
                <h1 className="title-right">{title}</h1>
                <p className="subtitle-right">{subtitle}</p>
            </div>
        </div>
    );
}

export default BackgroundFrameRight;
