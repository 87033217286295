import React from 'react'
import RSVPForm from './RSVPForm'
import './RSVP.css';
import CTAButton from './CTAButton.css';

export const RSVP = () => {
  return (
    <div className="rsvpPage">
      <div className="container">
        <RSVPForm />
      </div>
    </div>
  )
}
