import logo from './logo.svg';
import './App.css';
import {TestWrite} from './firebase';
import { BrowserRouter, Routes, Route, Link, NavLink  } from 'react-router-dom';
import {Home} from './Home';
import {Card} from './Card';
import { RSVP } from './RSVP';


function App() {

  // Name the domain lordylordytristas40.com or lordylordylookswhos40.com

  // A Daughter
  // A Sister
  // A Partner
  // A Student
  // A Gradute
  // A Teacher


  /*const coloredTitle = titleText.split('').map((letter, index) => (
    <span key={index} className={`rainbow-${index % 7}`}>{letter}</span>
  ));*/

  return (

    <div className="App">

      <BrowserRouter >

        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/card' element={ <Card /> } />
          <Route path='/rsvp' element={ <RSVP /> } />
        </Routes>

      </BrowserRouter> 
  
    </div>
  );
}

export default App;
