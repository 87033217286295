import React, { useState, useEffect } from 'react';
import {GetAllSignatures} from './firebase';
import './Card.css';
import { InfoPopup } from './InfoPopup';
import { SignaturePopup } from './SignaturePopup';
import CardForm from './CardForm';
import { collection, onSnapshot } from 'firebase/firestore';
import {db} from './firebase';

export const Card = () => {
  const backgroundImage = "https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fcard.PNG?alt=media&token=a0f12e1c-cee3-4c48-878d-70a16f44755e";
  
  const [isFlipped, setIsFlipped] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [currentSignature, setCurrentSignature] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
      if (showFullMessage) {
          const timer = setTimeout(() => {
              setShowDetails(true);
          }, 50); // short delay to start the fade-in

          return () => clearTimeout(timer); // Clear the timer if the component is unmounted
      } else {
          setShowDetails(false); // Reset the state when showFullMessage is false
      }
  }, [currentSignature]);


  const handleSignatureClick = (e, sig) => {
      e.stopPropagation();
      setShowDetails(false);
      setCurrentSignature(sig);
      setShowFullMessage(true);
  };

  const handleDetailsClick = (e) => {
    e.stopPropagation();
};

  useEffect(() => {
      console.log("currentSignature: ", currentSignature);
  }, [currentSignature]);

    const handleCloseMessage = (e) => {
        e.stopPropagation();
        setShowFullMessage(false);
    };

  function truncateString(str) {
      const maxLength = 250;
      if (str.length <= maxLength) {
          return str;
      }
      return str.slice(0, maxLength) + '...';
  }
  
  const randomColorMisc = () => {
    const colors = ['green', 'yellow', 'blue', 'black', 'red', 'orange', 'purple', 'pink', 'brown', 'grey'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const randomColor = () => {
    const colors = ['#ac3235', '#6699cc', '#2d2f31'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const randomFont = () => {
      const fonts = ['"Just Another Hand"' , '"Qwitcher Grypen"'];
      return fonts[Math.floor(Math.random() * fonts.length)];
  };

  const randomRotation = () => {
      const maxRotation = 10; // maximum rotation in degrees
      const rotation = Math.floor(Math.random() * (maxRotation * 2 + 1)) - maxRotation; // random value between -maxRotation and maxRotation
      return `${rotation}deg`;
  };


  const [allSignatures, setAllSignatures] = useState({});

  useEffect(() => {

    const fetchSignatures = async () => {
        try {
            const results = await GetAllSignatures();
            setAllSignatures(results);
        } catch (error) {
            console.error("Error fetching signatures:", error);
        }
    };

    fetchSignatures();
  }, []);

  // Listener for when the signatures are updated
  useEffect(() => {
    const signaturesRef = collection(db, 'signatures');

    const unsubscribe = onSnapshot(signaturesRef, (snapshot) => {
        const fetchedSignatures = [];
        snapshot.forEach(doc => {
            fetchedSignatures.push({ ...doc.data(), id: doc.id });
        });
        setAllSignatures(fetchedSignatures);
    });

    return () => unsubscribe();

}, []);


  useEffect(() => {
    console.log( "allSignatures: ", allSignatures );
  }, [allSignatures]);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="card-container">
      
      <div className={`flip-container ${isFlipped ? 'flipped' : ''}`} >
        
        <div className="flipper">

        

          <div className="front">

            <div className="card-background" style={{ backgroundImage: `url(${backgroundImage})` }}>

              <div className="card-text-container">
                <h1 className="card-title">"Experience is the most brutal of teachers but you learn, my God, do you learn."</h1>
                <p className="card-subtitle">- CS Lewis</p>
                <button className="flip-button" onClick={handleFlip}>Flip Card</button>
              </div>

              {/*<div className="click_info">
                  Click to flip card
              </div>*/}
              
            </div>

            
            
          </div>

          <div className="back">

            
            

            <div className="card-back-content">
                
                
                <h1 className="card-bottom-title">Happy 40th Birthday, Tree!</h1>
                <p className="card-bottom-subtitle">* When Trista was 16 years old, she crashed into a large tree with her Grandpa's 1973 Ford LTD and earned 
                        the nickname "Tree" from her siblings, the irony is "The Giving Tree" also happens to also be her favorite children's book.</p>
                
                <CardForm />
                {/*<InfoPopup />*/}

                {showFullMessage && (            
                  <div key={currentSignature.id}  className={`signature-details ${showDetails ? 'fade-in' : ''}`} onClick={(e) => handleDetailsClick(e)}>
                      
                      "{currentSignature.note}"  - {currentSignature.name}          
                  </div>
              
                )}

                <button className="flip-button" onClick={handleFlip}>Flip Card</button>
                
            </div>
 
            

            {isFlipped && <div className="signatures-container">

              

            { allSignatures !== null && allSignatures.length > 0 && allSignatures.map((signature, index) => (
                    <div 
                        key={index} 
                        className="signature-item" 
                        style={{ 
                            color: randomColor(), 
                            fontFamily: randomFont(), 
                            fontSize: '1.5rem',
                            marginInlineStart: '1rem',
                            marginTop: '1rem',
                            rotate: randomRotation(),
                            
                        }}
                        onClick={(e) => handleSignatureClick(e, signature)}
                    >
                        
                        {signature.name} <br />
                        <div className="signature-note">{truncateString(signature.note)}</div>
                    </div>
                ))}

                
            </div>}

            {/*showFullMessage && (
                    
                  

                  <SignaturePopup 
                      signature={currentSignature} 
                      onClose={(e) => handleCloseMessage(e)} 
                  />
                      
                      
                    
            )*/}


            
          </div>
        </div>
      </div>
    </div>
  );
}

