import React, { useState } from 'react';
import { SetRSVP } from './firebase';


const RSVPForm = () => {

    const [attending, setAttending] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [verifyEmail, setVerifyEmail] = useState('');
    const [note, setNote] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email !== verifyEmail) {
            setMessage('Email addresses do not match!');
            return;
        }

        await SetRSVP(name, email, note);

        // Reset
        setMessage('RSVP added successfully!');
        setName('');
        setEmail('');
        setVerifyEmail('');
        setNote('');
        setAttending(false);
    };

    const handleFormClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="form-container" onClick={handleFormClick}>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="attending"
                            checked={attending === 'attending'}
                            onChange={(e) => setAttending(e.target.value)}
                            required
                        />
                        Attending
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            value="not attending"
                            checked={attending === 'not attending'}
                            onChange={(e) => setAttending(e.target.value)}
                            required
                        />
                        Not Attending
                    </label>
                </div>
                <div>
                    <input
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <input
                        type="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <input
                        type="email"
                        placeholder="Verify Email"
                        value={verifyEmail}
                        onChange={(e) => setVerifyEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <textarea
                        placeholder="Best Wishes, dietary restrictions, etc."
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <button className='button-55' type="submit">Send</button>
                </div>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default RSVPForm;
