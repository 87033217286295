import React from 'react';
import '../src/BackgroundFrame.css';

const BackgroundFrame = ({ backgroundImage, title, subtitle }) => {
    return (
        <div className="background-frame" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="text-container">
                <h1 className="title">{title}</h1>
                <p className="subtitle">{subtitle}</p>
            </div>
        </div>
    );
}

export default BackgroundFrame;
