import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot, addDoc, deleteDoc, doc, 
    getDocs, query, where, getDoc, updateDoc, setDoc, Timestamp, serverTimestamp, orderBy, arrayUnion,
     limit, endAt, startAt, startAfter, endBefore, startBefore, FieldValue, FieldPath } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';


// Firebase Configuration
const firebaseConfig = {
    apiKey: "AIzaSyBo-LTRdn3H4XVkCChR2ASJ53Sgs4nV6Rk",
    authDomain: "tristas40.firebaseapp.com",
    projectId: "tristas40",
    storageBucket: "tristas40.appspot.com",
    messagingSenderId: "287998733717",
    appId: "1:287998733717:web:2e98117b92a3f409d99a9c"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const testColRef = collection( db, 'test_collection' );


// ----------------------- Card Signatures -----------------------//
export async function GetAllSignatures()
{
  const docsRef = collection(db, "signatures"); 
  const docs = [];

  const q = query( docsRef);

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    docs.push({ id: doc.id, ...doc.data() });
  });

  console.log(docs);
  return docs;
}

export async function WriteNewSignature( sname, snote ) 
{
    try {
        
      const docRef = await addDoc(collection(db, "signatures"), {
        name : sname,
        note : snote,
      });

    } catch (error) {
      console.error("Error setting document: ", error);
    }
}

// **********************************************//

// ----------------------- Test Functions -----------------------//
async function TestWrite() 
{
    const docRef = doc( testColRef, "test_doc");

    // test number
    const _randomNum = getRandomNumberInRange(1, 100); console.log( _randomNum );

    try {
        
      const _data = {
        temp : _randomNum,
      };

      await setDoc(docRef,  _data, { merge: true } );
    } catch (error) {
      console.error("Error setting document: ", error);
    }
}

function getRandomNumberInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

// ----------------------- Authentication Functions -----------------------//

// Signup Function
async function signup(email, password) {
  try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      return userCredential.user;
  } catch (error) {
      console.error("Error signing up: ", error);
      throw error;
  }
}

// Login Function
async function login(email, password) {
  try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
  } catch (error) {
      console.error("Error logging in: ", error);
      throw error;
  }
}

// Logout Function
async function logout() {
  try {
      await signOut(auth);
  } catch (error) {
      console.error("Error logging out: ", error);
      throw error;
  }
}

// ----------------------- RSVP Functions -----------------------//
async function SetRSVP( _name, _email, _note ) 
{
    try {
      
      const rsvpCol = collection(db, 'rsvp');
      
      await addDoc(rsvpCol, {
          name : _name,
          email : _email,
          note : _note,
          timestamp: new Date(),
      });

    } catch (error) {
      console.error("Error setting document: ", error);
    }
}

// ------------------------------------------------------------------------//

export { app, db, auth, signup, login, logout, TestWrite, SetRSVP }; 