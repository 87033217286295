import React from 'react';
import './GoogleMap.css';

const GoogleMap = () => {
    return (
        <div className="google-map-container">

            
            <iframe
                className="google-map-frame"
                referrerPolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA3aP5NLFbue03FgbcNSyhwAMu-KPUPsNw&q=7420+E+Tower+Mountain+Ln,+Spokane,+WA"
                allowFullScreen>
            </iframe>

            <h1 className="map-title">Location</h1>
            <p className="map-subtitle">The party will be held on Oct 27th, 6:00PM at our mountain home</p>
            
        </div>
    );
}

export default GoogleMap;
