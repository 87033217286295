import React, { useState } from 'react';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

const CardForm = () => {
    const [name, setName] = useState('');
    const [note, setNote] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const notesCollection = collection(db, 'signatures');
            await addDoc(notesCollection, {
                name: name,
                note: note,
                timestamp: new Date(),
            });
            setMessage('Note added successfully!');
            setName('');
            setNote('');
        } catch (error) {
            setMessage('Error adding note: ' + error.message);
        }
    };

    const handleFormClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="card-form-container" onClick={handleFormClick}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name:"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <textarea
                    placeholder="Note:"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    required
                />
                <button className='button-55' type="submit">Sign Card</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default CardForm;
