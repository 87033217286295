import React, {useState} from 'react';
import Navbar from '../src/Navbar';
import GoogleMap from './GoogleMap';
import BackgroundFrame from './BackgroundFrame';
import BackgroundFrameRight from './BackgroundFrameRight';
import Slideshow from './Slideshow';
import SignupForm from './SignupForm';
import CTAButton from './CTAButton.css';
import { useNavigate } from 'react-router-dom';
import ThinSection from './ThinSection';

export const Home = () => {

    const titleText = "Who's It For?";
    const navigate = useNavigate();

    function handleClick() {
        navigate('/rsvp');
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = () => {
    setIsModalOpen(true);
    };

    const handleModalClose = () => {
    setIsModalOpen(false);
    };


  return (
    <div>

        {/* Hero Section */}
        <div className="section_hero">

            <div className="flexChild">
                <div className="heroContainer">
                    <div className="heroText"> Welcome to Trista's 40th Bitrhday </div>
                    <div className="heroSubText">Sshhhhhh... it's a secret party</div>
                    <button className="button-55" role="button" onClick={ () => {handleClick();} }>&emsp;RSVP&emsp;</button>
                </div>

            </div>
            
        </div>

        {/* Card Section */}
        <div>
        <ThinSection 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fblue_card_long.png?alt=media&token=9e098f05-1907-4a0d-82ad-db3070d84ebb"
            title=""
            subtitle=""
        />
        </div>

        <div className="centered-section">
            <h1 className="centered-title" data-text={titleText}>
              {titleText}
            </h1>
        </div>

        <div>
            <Slideshow />
        </div>


      <div className="centered-section">
          <h1 className="centered-title" data-text={"When's The Party?"}>
            When's The Party?
          </h1>
      </div>

    <BackgroundFrame 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Foctober.jpeg?alt=media&token=98d3509e-5ab7-4a10-9ba9-38e8b23d295d"
            title="October 27th, 2023 @6:00pm"
            subtitle="Save the date!"
        />

      <div className="centered-section">
          <h1 className="centered-title" data-text={"Where's The Party?"}>
          Where's The Party?
          </h1>
      </div>

      {isModalOpen && (
        <div className="modal">
            <div className="modal-content">
            <span className="close-button" onClick={handleModalClose}>&times;</span>
            <img src="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fparking_locations.png?alt=media&token=11be7605-9b26-4e56-ad4e-ad1f5a2fbfa4" alt="Your Image" className="modal-image" />
            </div>
        </div>
        )}


    {/* Location Section */}
    <div className="section_location">

        <GoogleMap />

        <button className="parkingMap" onClick={handleModalOpen} >Parking Map</button>

    </div>


    <div className="centered-section">
        <h1 className="centered-title" data-text={"What Are We Doing?"}>
        What Are We Doing?
        </h1>
    </div>

    <BackgroundFrame 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fitalian.jpeg?alt=media&token=1ae9bd61-f373-45ce-9645-1291603242d7"
            title="Dinner Is Served"
            subtitle="Save room for Italian cuisine @6:00pm"
        />

    <BackgroundFrameRight 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fpiano.jpeg?alt=media&token=11f4007c-8f35-4f87-a201-24ec9bd78f17"
            title="The Sound Of Music"
            subtitle="Live piano music will be played @7:00pm. Tips are appreciated!"
        />

    <BackgroundFrame 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fsmores.jpeg?alt=media&token=370cc9a6-a0df-486f-9d01-6bc695f5b8bf"
            title="Have Some Smore"
            subtitle="Induldge in a smore bar with chocolate fountain and tons of toppings @8:00pm"
        />

    <BackgroundFrameRight 
            backgroundImage="https://firebasestorage.googleapis.com/v0/b/tristas40.appspot.com/o/assets%2Fenjoy_others.jpeg?alt=media&token=3aecb23b-4a61-42bb-ad54-3fe40548a9bd"
            title="And Most Importantly..."
            subtitle="Come enjoy fellowship with friends and family. We're so excited to see you there!"
        />

    { /* End Section */ }
    <div className="centered-section">
        
        <p className='centered-subtitle' >Still have questions? &nbsp; <a href="mailto:tristas40th@gmail.com?subject=Happy%2040th%20Birthday&body=Dear%20Trista,%0D%0A%0D%0AWe%20are%20so%20excited%20to%20celebrate%20your%2040th%20birthday%20with%20you!%0D%0ALooking%20forward%20to%20the%20celebrations.%0D%0A%0D%0ABest,%0D%0A[Your%20Name]">  Email Me </a></p>

    </div>
    
    </div>
  )
}
