import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../src/ThinSection.css';
import CTAButton from './CTAButton.css';

const ThinSection = ({ backgroundImage, title, subtitle }) => {

    const navigate = useNavigate();

    function handleClick() {
        navigate('/card');
    }

    return (
        <div className="thin-background-frame" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="thin-text-container">
                <h1 className="thin-title">{title}</h1>
                <p className="thin-subtitle">{subtitle}</p>
            </div>
            <button className="button-55" role="button" onClick={ () => {handleClick();} }>&emsp;Sign Her Card&emsp;</button>
        </div>
    );
}

export default ThinSection;
